@tailwind base;
@tailwind components;
@tailwind utilities;

.ProseMirror,
.tiptap {
  padding: 0 !important;
  padding-top: 3.125rem !important;
  padding-left: var(--padding-left) !important;
  padding-right: var(--padding-right) !important;

  & > * {
    max-width: 100% !important;
    padding: 0 !important;
  }

  .is-empty::before {
    @apply text-neutral-500 dark:text-neutral-400;
  }

  & > *:first-child,
  [data-type="column"] > * {
    @apply first:mt-0 last:mb-0;
  }

  & > * + * {
    margin-top: 0.75em;
  }

  .node-imageUpload {
    @apply rounded border-2 border-dotted border-black border-opacity-10 p-2 dark:border-neutral-500;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-opacity-30;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-opacity-40;
    }
  }

  [data-type="columns"] {
    &.has-focus [data-type="column"],
    &:hover [data-type="column"] {
      @apply border-neutral-300 dark:border-neutral-700;
    }

    [data-type="column"].has-focus {
      @apply border-neutral-400 dark:border-neutral-600;
    }
  }

  [data-type="column"] {
    @apply rounded border-2 border-dotted border-transparent p-1;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);

    &:hover {
      @apply border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active),
    &.has-focus {
      @apply border-neutral-100 dark:border-neutral-900;
    }
  }

  .node-imageBlock {
    & img {
      @apply border-2 border-transparent rounded-xl overflow-hidden;
    }

    &:hover img {
      @apply border-2 border-neutral-100 dark:border-neutral-900;
    }

    &:has(.is-active) img,
    &.has-focus img {
      @apply border-2 border-neutral-800 dark:border-neutral-200;
    }
  }

  .node-aiWriter,
  .node-aiImage,
  .node-tableOfContentsNode {
    &.has-focus [data-node-view-wrapper] > * {
      @apply shadow-[0_0_0_2px] shadow-black/30 dark:shadow-white/30 transition-all rounded-lg;
    }
  }

  .ProseMirror-gapcursor + .node-imageBlock,
  .ProseMirror-gapcursor + .node-imageUpload {
    @apply outline-neutral-700 hover:outline-neutral-600 dark:outline-neutral-300 dark:hover:outline-neutral-400;
  }
}

.ProseMirror,
.tiptap {
  /* Image Block */
  figure[data-type="imageBlock"] {
    @apply m-0;

    img {
      @apply rounded block w-full;
    }
  }

  /* Block Quote */

  & > blockquote,
  [data-type="blockquoteFigure"] {
    blockquote {
      @apply m-0;

      & > * {
        @apply first:mt-0 last:mb-0;
      }
    }
  }

  /* Columns */
  [data-type="columns"] {
    @apply grid gap-4 mt-14 mb-12;

    &.layout-sidebar-left {
      grid-template-columns: 40fr 60fr;
    }

    &.layout-sidebar-right {
      grid-template-columns: 60fr 40fr;
    }

    &.layout-two-column {
      grid-template-columns: 1fr 1fr;
    }
  }

  [data-type="column"] {
    @apply overflow-auto;
  }
}

/* @import "./partials/code.css"; */

.ProseMirror,
.tiptap {
  code {
    @apply caret-white text-white bg-neutral-900 rounded-sm shadow-lg font-mono;

    &::selection {
      @apply bg-white/30;
    }
  }

  pre {
    @apply caret-white bg-neutral-700 text-white rounded my-12 p-4 border border-black dark:bg-neutral-900 dark:border-neutral-800;

    *::selection {
      @apply bg-white/20;
    }

    code {
      @apply bg-inherit text-inherit p-0 shadow-none;
    }

    .hljs-comment,
    .hljs-quote {
      @apply text-neutral-400;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      @apply text-red-300;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      @apply text-orange-300;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      @apply text-lime-300;
    }

    .hljs-title,
    .hljs-section {
      @apply text-yellow-300;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      @apply text-teal-300;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
}

/* @import "./partials/collab.css"; */

.ProseMirror,
.tiptap {
  .collaboration-cursor {
    &__caret {
      @apply border-r border-l border-black pointer-events-none -ml-[1px] -mr-[1px] relative break-normal;
    }

    &__label {
      @apply rounded rounded-tl-none text-black text-xs font-semibold -left-[1px] leading-none py-0.5 px-1.5 absolute -top-[1.4em] select-none whitespace-nowrap;
    }
  }
}

/* Lists */
.ProseMirror ol,
.tiptap ol {
  @apply list-decimal;
}

.ProseMirror ul,
.tiptap ul {
  @apply list-disc;
}

.ProseMirror ul,
.ProseMirror ol,
.tiptap ul,
.tiptap ol {
  @apply py-0 px-8 my-6 first:mt-0 last:mb-0;
}

.ProseMirror ul ul,
.ProseMirror ul ol,
.ProseMirror ul li,
.ProseMirror ol ul,
.ProseMirror ol ol,
.ProseMirror ol li,
.tiptap ul ul,
.tiptap ul ol,
.tiptap ul li,
.tiptap ol ul,
.tiptap ol ol,
.tiptap ol li {
  @apply my-1;
}

.ProseMirror ul p,
.ProseMirror ol p,
.tiptap ul p,
.tiptap ol p {
  @apply mt-0 mb-1;
}

.ProseMirror > ul,
.ProseMirror > ol,
.tiptap > ul,
.tiptap > ol {
  @apply my-2.5 first:mt-0 last:mb-0;
}

.ProseMirror ul[data-type="taskList"],
.tiptap ul[data-type="taskList"] {
  @apply list-none p-0;
}

.ProseMirror ul[data-type="taskList"] p,
.tiptap ul[data-type="taskList"] p {
  @apply m-0;
}

.ProseMirror ul[data-type="taskList"] li,
.tiptap ul[data-type="taskList"] li {
  @apply flex;
}

.ProseMirror ul[data-type="taskList"] li > label,
.tiptap ul[data-type="taskList"] li > label {
  @apply grow-0 shrink-0 flex-auto mt-1 mr-2 select-none;
}

.ProseMirror ul[data-type="taskList"] li > div,
.tiptap ul[data-type="taskList"] li > div {
  @apply flex-auto;
}

.ProseMirror ul[data-type="taskList"] li[data-checked="true"],
.tiptap ul[data-type="taskList"] li[data-checked="true"] {
  @apply line-through;
}

/* Placeholder Styles */
.ProseMirror .is-empty::before,
.tiptap .is-empty::before {
  @apply text-[rgba(236,236,236,0.26)] float-left h-0 pointer-events-none w-full;
}

.ProseMirror.ProseMirror-focused > p.has-focus.is-empty::before,
.tiptap.ProseMirror-focused > p.has-focus.is-empty::before {
  content: "Tippen Sie / um Optionen zu durchsuchen";
}

.ProseMirror.ProseMirror-focused
  > [data-type="columns"]
  > [data-type="column"]
  > p.is-empty.has-focus::before,
.tiptap.ProseMirror-focused
  > [data-type="columns"]
  > [data-type="column"]
  > p.is-empty.has-focus::before {
  content: "Tippen Sie / um Optionen zu durchsuchen";
}

.ProseMirror > .is-editor-empty::before,
.tiptap > .is-editor-empty::before {
  content: "Klicken Sie hier, um mit dem Schreiben zu beginnen …";
}

.ProseMirror
  blockquote
  .is-empty:not(.is-editor-empty):first-child:last-child::before,
.tiptap
  blockquote
  .is-empty:not(.is-editor-empty):first-child:last-child::before {
  content: "Geben Sie ein Zitat ein";
}

.ProseMirror blockquote + figcaption.is-empty:not(.is-editor-empty)::before,
.tiptap blockquote + figcaption.is-empty:not(.is-editor-empty)::before {
  content: "Autor";
}

[data-placeholder][data-suggestion]::before,
[data-placeholder][data-suggestion] *::before {
  content: none !important;
}

/* Table Styles */
.ProseMirror .tableWrapper,
.tiptap .tableWrapper {
  @apply my-12;
}

.ProseMirror table,
.tiptap table {
  @apply border-collapse border-black/10 rounded box-border w-full;
  @apply dark:border-white/20;
}

.ProseMirror table td,
.ProseMirror table th,
.tiptap table td,
.tiptap table th {
  @apply border border-black/10 min-w-[100px] p-2 relative text-left align-top;
  @apply dark:border-white/20;
}

.ProseMirror table td:first-of-type:not(a),
.ProseMirror table th:first-of-type:not(a),
.tiptap table td:first-of-type:not(a),
.tiptap table th:first-of-type:not(a) {
  @apply mt-0;
}

.ProseMirror table p,
.tiptap table p {
  @apply m-0;
}

.ProseMirror table p + p,
.tiptap table p + p {
  @apply mt-3;
}

.ProseMirror table th,
.tiptap table th {
  @apply font-bold;
}

.ProseMirror table .column-resize-handle,
.tiptap table .column-resize-handle {
  @apply -bottom-[2px] flex pointer-events-none absolute -right-1 top-0 w-2;
}

.ProseMirror table .column-resize-handle::before,
.tiptap table .column-resize-handle::before {
  @apply bg-black/20 h-full w-[1px] ml-2;
  @apply dark:bg-white/20;
  content: "";
}

.ProseMirror table .selectedCell,
.tiptap table .selectedCell {
  @apply bg-black/5 border-black/20 border-double;
  @apply dark:bg-white/10 dark:border-white/20;
}

.ProseMirror table .grip-column,
.ProseMirror table .grip-row,
.tiptap table .grip-column,
.tiptap table .grip-row {
  @apply items-center bg-black/5 cursor-pointer flex justify-center absolute z-10;
  @apply dark:bg-white/10;
}

.ProseMirror table .grip-column,
.tiptap table .grip-column {
  @apply w-[calc(100%+1px)] border-l border-black/20 h-3 left-0 -ml-[1px] -top-3;
  @apply dark:border-white/20;
}

.ProseMirror table .grip-column:hover,
.ProseMirror table .grip-column.selected,
.tiptap table .grip-column:hover,
.tiptap table .grip-column.selected {
  @apply bg-black/10;
  @apply dark:bg-white/20;
}

.ProseMirror table .grip-column:hover::before,
.ProseMirror table .grip-column.selected::before,
.tiptap table .grip-column:hover::before,
.tiptap table .grip-column.selected::before {
  @apply border-b-2 border-dotted border-black/60;
  @apply dark:border-white/60;
}

.ProseMirror table .grip-column.first,
.tiptap table .grip-column.first {
  @apply border-transparent rounded-tl-sm;
}

.ProseMirror table .grip-column.last,
.tiptap table .grip-column.last {
  @apply rounded-tr-sm;
}

.ProseMirror table .grip-column.selected,
.tiptap table .grip-column.selected {
  @apply bg-black/30 border-black/30 shadow-sm;
  @apply dark:bg-white/30 dark:border-white/30;
}

.ProseMirror table .grip-column.selected::before,
.tiptap table .grip-column.selected::before {
  @apply border-b-2 border-dotted;
}

.ProseMirror table .grip-row,
.tiptap table .grip-row {
  @apply h-[calc(100%+1px)] border-t border-black/20 -left-3 w-3 top-0 -mt-[1px];
  @apply dark:border-white/20;
}

.ProseMirror table .grip-row:hover,
.ProseMirror table .grip-row.selected,
.tiptap table .grip-row:hover,
.tiptap table .grip-row.selected {
  @apply bg-black/10;
  @apply dark:bg-white/20;
}

.ProseMirror table .grip-row:hover::before,
.ProseMirror table .grip-row.selected::before,
.tiptap table .grip-row:hover::before,
.tiptap table .grip-row.selected::before {
  @apply border-l-2 border-dotted border-black/60;
  @apply dark:border-white/60;
}

.ProseMirror table .grip-row.first,
.tiptap table .grip-row.first {
  @apply border-transparent rounded-tl-sm;
}

.ProseMirror table .grip-row.last,
.tiptap table .grip-row.last {
  @apply rounded-bl-sm;
}

.ProseMirror table .grip-row.selected,
.tiptap table .grip-row.selected {
  @apply bg-black/30 border-black/30 shadow-sm;
  @apply dark:bg-white/30 dark:border-white/30;
}

.ProseMirror table .grip-row.selected::before,
.tiptap table .grip-row.selected::before {
  @apply border-l-2 border-dotted;
}

/* Typography */
.ProseMirror p,
.tiptap p {
  @apply leading-relaxed my-3 first:mt-0 last:mb-0;
}

.ProseMirror > p,
.tiptap > p {
  @apply my-0.5 first:mt-0 last:mb-0;
}

.ProseMirror h1,
.tiptap h1 {
  @apply text-3xl;
}

.ProseMirror h2,
.tiptap h2 {
  @apply text-2xl;
}

.ProseMirror h3,
.tiptap h3 {
  @apply text-xl;
}

.ProseMirror h4,
.tiptap h4 {
  @apply text-lg;
}

.ProseMirror h5,
.tiptap h5 {
  @apply text-base;
}

.ProseMirror h6,
.tiptap h6 {
  @apply text-sm;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6,
.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  @apply font-bold first:mt-0 last:mb-0;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.tiptap h1,
.tiptap h2,
.tiptap h3 {
  @apply mt-[1.8125rem];
}

.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
  @apply mt-8;
}

.ProseMirror a.link,
.tiptap a.link {
  @apply text-blue-500 font-extrabold dark:text-blue-400;
}

.ProseMirror mark,
.tiptap mark {
  @apply bg-red-500 rounded-sm decoration-clone text-inherit py-1 px-0 dark:bg-red-400;
}

.ProseMirror img,
.tiptap img {
  @apply h-auto w-full max-w-full;
}

[data-type="horizontalRule"] {
  @apply my-8 py-4 transition-all duration-100 ease-in-out cursor-pointer;
}

[data-type="horizontalRule"].ProseMirror-selectednode,
[data-type="horizontalRule"].tiptap-selectednode {
  @apply bg-black/5 dark:bg-white/10;
}

[data-type="horizontalRule"].ProseMirror-selectednode hr,
[data-type="horizontalRule"].tiptap-selectednode hr {
  @apply border-t-black/30 dark:border-t-white/30;
}

[data-type="horizontalRule"]:hover:not(.ProseMirror-selectednode),
[data-type="horizontalRule"]:hover:not(.tiptap-selectednode) {
  @apply bg-black/5 dark:bg-white/10;
}

[data-type="horizontalRule"] hr {
  @apply border-0 border-t border-black/20 bg-black/80;
  @apply dark:border-white/20 dark:bg-white/80;
}

/* supabase style overwrites */
.supabase-auth-ui_ui-label {
  display: none !important;
}

.ProseMirror,
.tiptap {
  @apply caret-white  outline-0 pr-8 pl-20 py-16 z-0 lg:pl-8 lg:pr-8;

  & > * {
    @apply mx-auto max-w-2xl;
  }

  .selection {
    @apply inline;
  }

  .selection,
  *::selection {
    @apply bg-white/20;
  }

  & > .react-renderer {
    @apply my-12 first:mt-0 last:mb-0;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  .ProseMirror-gapcursor {
    @apply relative w-full max-w-2xl mx-auto;

    &:after {
      @apply border-t-black/40 dark:border-t-white/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
    }
  }
}

[data-theme="slash-command"] {
  width: 1000vw;
}

.tiptap blockquote,
.custom-blockquote {
  background-color: rgba(52, 50, 49, 0.25) !important;
  color: rgba(255, 255, 255, 0.81) !important;
  border-left: 3px solid rgba(255, 255, 255, 0.81);
  border-radius: 3px;
  padding: 0.4375rem 1.3125rem !important;
  font-family: Charter;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  margin: 0.6875rem 0;
}

.tiptap h6 {
  display: block !important;
  padding: 1rem 1.1875rem !important;
  color: #ffffffcf !important;
  font-family: Charter !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: #29282640 !important;
}

.modal-background {
  position: fixed; /* or 'absolute' */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tiptap ol,
.tiptap ul {
  margin-left: 1rem;
}

a[data-type="citation"] {
  display: inline;
  justify-content: center;
  align-items: center;
  background-color: #292826;
  color: #ffffff80;
  font-weight: 500;
  font-size: 0.625rem;
  cursor: pointer;
  border-radius: 2px;
  height: 1rem;
  min-width: 1rem;
  text-align: center;
  text-decoration: none;

  white-space: normal !important; /* Ensures that whitespace is handled normally */
  word-wrap: break-word !important; /* Allows long words to be broken and wrapped to the next line */
  overflow-wrap: break-word !important; /* Alternative to word-wrap, more modern property name */
  word-break: break-word;
}

.tiptap .citation-index {
  background-color: #1c1b1b;
  padding: 0.25rem 0.325rem;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}
.tiptap .citation-source {
  background-color: inherit;
  padding: 0.25rem;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}

.tiptap ul[data-type="taskList"] {
  margin-left: 0 !important;
}

.tiptap input[type="checkbox"] {
  border: 2px solid rgba(255, 255, 255, 0.81) !important;
  height: 1rem;
  width: 1rem;
  background-color: #29282640 !important;
}

.tiptap input[type="checkbox"]:checked {
  background-color: #29282640 !important;
}

/* @import "./partials/animations.css"; */

.spinner-animation {
  animation: spinner 1.6s linear infinite;
  animation-delay: -1.6s;
}

.spinner-animation-secondary {
  animation-delay: -1s;
}

/* @keyframes spinner {
  12.5 % {
    x: 13px;
    y: 1px;
  }
  25% {
    x: 13px;
    y: 1px;
  }
  37.5% {
    x: 13px;
    y: 13px;
  }
  50% {
    x: 13px;
    y: 13px;
  }
  62.5% {
    x: 1px;
    y: 13px;
  }
  75% {
    x: 1px;
    y: 13px;
  }
  87.5% {
    x: 1px;
    y: 1px;
  }
} */

/* @import "./partials/blocks.css"; */
